import {
  MedicalInstitutionReportFilterCondition,
  MedicalInstitutionReportFilterConditionMatcher,
} from '@/src/api/generated';
import { MedicalInstitutionLabelFilterConditionSchema } from '@/src/types/medicalInstitutionLabel';

import { REST_AREA_ID } from './const';

import compact from 'lodash.compact';
import pickBy from 'lodash.pickby';

import { createMedicalInstitutionLabelReportItemDefinitionKey } from '../createMedicalInstitutionLabelReportItemDefinitionKey';

export const medicalInstitutionLabel2FilterCondition = (
  c: MedicalInstitutionLabelFilterConditionSchema,
): MedicalInstitutionReportFilterCondition => ({
  item: {
    key: createMedicalInstitutionLabelReportItemDefinitionKey(c.labelId),
  },
  matchingGroups: toMedicalInstitutionLabelMatchingGroups(c),
});

// eslint-disable-next-line complexity
const toMedicalInstitutionLabelMatchingGroups = (
  c: MedicalInstitutionLabelFilterConditionSchema,
): MedicalInstitutionReportFilterConditionMatcher[] => {
  if (c.textCondition?.value) {
    return [{ contain: c.textCondition?.value }];
  } else if (c.integerCondition?.conditionType === 'moreEqual') {
    return [{ moreEqual: c.integerCondition?.value }];
  } else if (c.integerCondition?.conditionType === 'lessEqual') {
    return [{ lessEqual: c.integerCondition?.value }];
  } else if (c.selectionCondition?.conditionType === 'in') {
    const ids = new Set(c.selectionCondition.ids);
    const hasRestAreaId = ids.delete(REST_AREA_ID);

    // 以下の2つを OR 条件で結合したリクエストを作成している
    // - 「その他エリア」以外を"in"条件にマッピング
    // - 「その他エリア」をIDなしを検索する"exists: false"にマッピング
    return compact([
      ids.size > 0 ? { _in: Array.from(ids).map(Number) } : undefined,
      hasRestAreaId ? { _exists: false } : undefined,
    ]);
  } else if (c.selectionCondition?.conditionType === 'notIn') {
    const ids = new Set(c.selectionCondition.ids);
    const hasRestAreaId = ids.delete(REST_AREA_ID);

    // 以下の2つを AND 条件で結合したリクエストを作成している
    // - 「その他エリア」以外を"notIn"条件にマッピング
    // - 「その他エリア」をIDありを検索する"exists: true"にマッピング
    return [
      pickBy({
        notIn: ids.size > 0 ? Array.from(ids).map(Number) : undefined,
        _exists: hasRestAreaId ? true : undefined,
      }),
    ];
  } else if (c.selectionCondition?.conditionType === 'haveAny') {
    return [{ haveAny: c.selectionCondition?.ids.map(Number) }];
  } else if (c.selectionCondition?.conditionType === 'haveAll') {
    return [{ haveAll: c.selectionCondition?.ids.map(Number) }];
  } else if (c.selectionCondition?.conditionType === 'notHaveAll') {
    return [{ notHaveAll: c.selectionCondition?.ids.map(Number) }];
  } else {
    throw new Error('invalid condition');
  }
};
